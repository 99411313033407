<script setup>

import { onMounted, ref } from "vue"
import { useHfmStore } from "@/store/hfm"
import { useRoute } from 'vue-router'

import FormHfm from "@/components-uhas/forms/FormHfm.vue"
import UtilityButtonBack from "@/components-uhas/utilities/UtilityButtonBack.vue"

const hfmStore = useHfmStore()
const route = useRoute()

const hfm = ref({ _: false })
const loading = ref(false)

onMounted(() => {
  loading.value = true
  hfmStore.show(route.params.id).then(({ data }) => {
    if (data.error) {
      return
    }

    hfm.value = { ...data.hfm }

    loading.value = false
  })
})

</script>

<template>
  <div class="block-section">
    <div class="block-header">
        <span class="block-title">
          <div>Show #{{ hfm.id }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <UtilityButtonBack />
        </div>
    </div>
    <div class="block-content">
        <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
          <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <div>
              
              <FormHfm
                v-if="hfm._ !== false"
                v-model="hfm"
              />

            </div>
          </div>
        </div>
    </div>
  </div>
</template>
